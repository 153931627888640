// Generated by Framer (10753fe)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xEzoxiILv"];

const variantClassNames = {xEzoxiILv: "framer-v-12o2z98"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 4, stiffness: 182, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string}; radius?: number; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "xEzoxiILv", image: WUIidsuV5, radius: A2gLS_V82 = 0, tap: BnEVkgqMT, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xEzoxiILv", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapmxcumo = activeVariantCallback(async (...args) => {
if (BnEVkgqMT) {
const res = await BnEVkgqMT(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mxXjf", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} background={{alt: "", fit: "fill"}} className={cx("framer-12o2z98", className)} data-framer-name={"Image - Fill"} data-highlight layoutDependency={layoutDependency} layoutId={"xEzoxiILv"} onTap={onTapmxcumo} ref={ref} style={{borderBottomLeftRadius: A2gLS_V82, borderBottomRightRadius: A2gLS_V82, borderTopLeftRadius: A2gLS_V82, borderTopRightRadius: A2gLS_V82, ...style}} transition={transition}><Image background={{alt: "", fit: "fill", sizes: "min(200px, 100vw)", ...toResponsiveImage(WUIidsuV5)}} className={"framer-19xvjtt"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"C8gEDTC_C"} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mxXjf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mxXjf .framer-1fytxov { display: block; }", ".framer-mxXjf .framer-12o2z98 { cursor: pointer; height: 200px; overflow: visible; position: relative; width: 200px; }", ".framer-mxXjf .framer-19xvjtt { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WUIidsuV5":"image","A2gLS_V82":"radius","BnEVkgqMT":"tap"}
 */
const FramerScje52YU8: React.ComponentType<Props> = withCSS(Component, css, "framer-mxXjf") as typeof Component;
export default FramerScje52YU8;

FramerScje52YU8.displayName = "Elemental/ Image";

FramerScje52YU8.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerScje52YU8, {WUIidsuV5: {title: "Image", type: ControlType.ResponsiveImage}, A2gLS_V82: {defaultValue: 0, displayStepper: true, step: 8, title: "Radius", type: ControlType.Number}, BnEVkgqMT: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerScje52YU8, [])